<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <el-card class="box-card" style="text-align: left;">
      <div slot="header" class="clearfix">
        <span>商户管理</span>
      </div>
      <div>

        <br/>

        <el-form :inline="true" label-width="100px">
          <el-form-item label="商户名">
            <el-input size="small" v-model="merName" clearable></el-input>
          </el-form-item>

<!--          <el-form-item label="商户类别">-->
<!--            <el-select size="small" v-model="type" clearable filterable>-->
<!--              <el-option :value=1 label="和上"></el-option>-->
<!--              <el-option :value=2 label="寺庙"></el-option>-->
<!--              <el-option :value=3 label="商户"></el-option>-->
<!--              <el-option :value=4 label="部门"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->

          <el-form-item label="审核状态">
            <el-select size="small" v-model="status" clearable filterable>
              <el-option value="4" label="寺庙审核中"></el-option>
              <el-option value="6" label="和上审核中"></el-option>
              <el-option value="8" label="银行审核中"></el-option>
              <el-option value="10" label="营业中"></el-option>
              <el-option value="11" label="暂停中"></el-option>
              <el-option value="12" label="禁用中"></el-option>
              <el-option value="5" label="寺庙审核不同意"></el-option>
              <el-option value="7" label="和上审核不同意"></el-option>
              <el-option value="9" label="银行审核不同意"></el-option>
              <el-option value="2" label="编辑法人信息中"></el-option>
              <el-option value="3" label="编辑银行信息中"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="padding-left:20px;">
            <el-button type="primary" size="small" style="margin-right:10px;" @click="getList">查询</el-button>
          </el-form-item>
        </el-form>

        <br/>

        <el-table :data="list" stripe style="margin-top:10px;">
          <el-table-column type="index" label="序号" width="50" header-align="center" align="center"/>
          <el-table-column prop="merName" width="300" label="商户名" header-align="center" align="center">
            <template slot-scope="scope">
              <span class="link" @click="watchItem(scope.row.id)">{{ scope.row.merName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="merId" width="300" label="商铺号" header-align="center" align="center"/>
          <el-table-column prop="statusMessage" width="150" label="审核状态" header-align="center" align="center"/>
          <el-table-column prop="rate" label="手续费率(%)" header-align="center" align="center"/>
          <el-table-column prop="parentRate" label="抽成比例(%)" header-align="center" align="center"/>
          <el-table-column prop="intoAccountDuration" label="清分账期(天)" header-align="center" align="center"/>
          <el-table-column prop="autoReceiptLabel" label="是否自动到账" header-align="center" align="center"/>
          <el-table-column label="操作" header-align="center" align="center" width="150">
            <template slot-scope="scope">
              <el-row>
                <el-button type="primary" size="mini" @click="modifyInformation(scope.row.id)" class="m-2"
                           v-if="hasPermission(scope.row,1)">编辑
                </el-button>
                <el-button type="danger" size="mini" @click="modifyRate(scope.row)" class="m-2"
                           v-if="hasPermission(scope.row,1)">费率账期
                </el-button>
                <el-button type="primary" size="mini" @click="allocationAdmin(scope.row.id)" class="m-2"
                           v-if="hasPermission(scope.row,1)">分配账号
                </el-button>
                <el-button type="success" size="mini" @click="operate(scope.row.id,1)" class="m-2"
                           v-if="hasPermission(scope.row,1)&&scope.row.operationPermissionList[0]">启用
                </el-button>
                <el-button type="warning" size="mini" @click="operate(scope.row.id,2)" class="m-2"
                           v-if="hasPermission(scope.row,1)&&scope.row.operationPermissionList[1]">停用
                </el-button>
              </el-row>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination style="margin: 10px 0 0 -10px;" background :page-size="pageSize" layout="total,prev, pager, next"
                       :total="total" :current-page="currentPage" @current-change="handleCurrentChange"/>
      </div>
    </el-card>

    <el-dialog :visible.sync="is_show" :before-close="clearParams">
      <el-form :inline="false" class="form" style="text-align: left;" label-width="100px" ref="form" :model="params"
               :rules="rules">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="现有未分配账号" name="1">
            <el-form-item label="选取账号" class="form-item" prop="admin_id">
              <el-select v-model="params.admin_id" clearable>
                <el-option v-for="item in admin_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="新增账号" name="2">
            <el-form-item label="账户" class="form-item" prop="username">
              <el-input v-model="params.username" placeholder="请填写用户名" size="small"></el-input>
            </el-form-item>

            <el-form-item label="密码" class="form-item" prop="password">
              <el-input :type="passwordType" v-model="params.password" placeholder="请填写密码" size="small"
                        auto-complete="off">
                <i class="el-icon-view el-input__icon"
                   slot="suffix"
                   @click="handleIconClick">
                </i>
              </el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <el-form-item style="text-align:left;">
          <el-button type="button" class="el-button--primary" @click="allocate" style="margin-right:20px;">提交
          </el-button>
          <el-button type="button" class="el-button--primary" @click="clearParams" style="margin-right:20px;">取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="isVisible" :before-close="clearRateParams">
      <el-form :inline="false" class="form" style="text-align: left;" label-width="120px" :model="rateParams"
               :rules="rateRules" ref="rateForm">

        <el-form-item label="手续费率(%)" class="form-item" prop="rate">
          <el-input v-model="rateParams.rate" placeholder="请填写手续费率" size="small"></el-input>
          <span style="color: red;font-size: 12px;">输入1则代表手续费率为1%</span>
        </el-form-item>

        <el-form-item label="抽成比例(%)" class="form-item" prop="parentRate">
          <el-input v-model="rateParams.parentRate" placeholder="请填写抽成比例" size="small"></el-input>
          <span style="color: red;font-size: 12px;">输入1则代表费率为1%</span>
        </el-form-item>

        <el-form-item label="清分账期(天)" class="form-item" prop="intoAccountDuration">
          <el-input-number v-model="rateParams.intoAccountDuration" :min="1" label="输入1则代表清分账期为1天"></el-input-number>
          <span style="color: red;font-size: 12px;padding-left: 10px;">输入1则代表清分账期为1天</span>
        </el-form-item>
        <el-form-item label="是否自动到账" class="form-item" prop="isAutoReceive">
          <el-checkbox v-model="rateParams.autoReceipt"></el-checkbox>
          <!--          <span style="color: red;font-size: 12px;padding-left: 10px;">输入1则代表清分账期为1天</span>-->
        </el-form-item>
        <el-form-item style="text-align:left;">
          <el-button type="button" class="el-button--primary" @click="modify" style="margin-right:20px;">提交</el-button>
          <el-button type="button" class="el-button--primary" @click="clearRateParams" style="margin-right:20px;">取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index.vue",
  data() {
    const admin_id = (rule, value, callback) => {
      if (this.params.type === "1") {
        if (!this.params.admin_id) {
          callback(new Error('请选择账户'));
        } else {
          callback()
        }
      } else {
        callback();
      }
    };

    const username = (rule, value, callback) => {
      if (this.params.type === "2") {
        if (!this.params.username) {
          callback(new Error('请输入账号'));
        } else {
          callback()
        }
      } else {
        callback();
      }
    };

    const password = (rule, value, callback) => {
      if (this.params.type === "2") {
        if (!this.params.password) {
          callback(new Error('请输入密码'));
        } else {
          if (this.params.password.length < 6) {
            callback(new Error('密码至少为6位'));
          } else {
            callback()
          }
        }
      } else {
        callback();
      }
    };
    return {
      fullscreenLoading: false,
      list: [],
      currentPage: 1,
      pageSize: 20,
      type: null,
      status: null,
      id: localStorage.getItem("admin_id"),
      merName: null,
      is_show: false,
      statusList:[],
      activeName: "1",
      passwordType: 'password',
      admin_id,
      password,
      username,
      params: {
        admin_id: null,
        merchant_id: null,
        username: null,
        password: null,
        type: "1"
      },
      admin_list: [],
      total: 0,
      isVisible: false,
      rateParams: {
        id: null,
        parentRate: null,
        intoAccountDuration: null,
        autoReceipt: null,
        rate: null
      },
      rateRules: {
        parentRate: [
          {required: true, trigger: 'blur', message: '费率不能为空'},
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请输入正确额格式,可保留两位小数'
          }
        ]
      },
      rules: {
        admin_id: [{validator: admin_id, trigger: 'change'}],
        username: [{validator: username, trigger: 'blur'}],
        password: [{validator: password, trigger: 'blur'}],
      }
    }
  },
  mounted() {
    this.getList()
    this.getStatusList();
  },
  methods: {
    handleIconClick() {
      return this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    getStatusList() {
      let _this = this
      _this.fullscreenLoading = true
      adminHttp.post('/backend/merchant/getMerchantVerifyStatusList').then(result => {
        if (result && result.code === 200) {
          _this.statusList = result.data.statusList;
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    handleClick(tab, event) {
      this.params.type = tab.name;
      if ("1" === tab.name) {
        this.params.username = null;
        this.params.password = null;
      } else if ("2" === tab.name) {
        this.params.admin_id = null;
      }
    },
    allocate() {
      let _this = this
      let params = {
        admin_id: _this.params.admin_id,
        merchant_id: _this.params.merchant_id,
        username: _this.params.username,
        password: _this.params.password ? _this.$md5(_this.params.password) : null,
        type: _this.params.type
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          _this.fullscreenLoading = true
          adminHttp.post('/backend/merchant/allocatedAdmin', params).then(result => {
            if (result && result.code === 200) {
              _this.$message.success("操作成功");
              _this.clearParams();
              _this.getList();
            } else {
              _this.$message.error(result.message)
            }
          }).catch(error => {
            _this.$message.error('请求错误')
          }).finally(() => {
            _this.fullscreenLoading = false
          })
        }
      })
    },
    allocationAdmin(value) {
      let _this = this
      _this.fullscreenLoading = true
      adminHttp.post('/backend/merchant/queryUnallocatedAdminList').then(result => {
        if (result && result.code === 200) {
          _this.admin_list = result.data;
          _this.params.merchant_id = value;
          _this.is_show = true;
        } else {
          _this.$message.error("暂无可分配用户，清先创建新的用户！")
        }
      }).catch(error => {
        _this.$message.error('获取用户列表失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    clearParams() {
      this.params = {
        admin_id: null,
        merchant_id: null,
        username: null,
        password: null,
        type: null
      }
      this.is_show = false;
    },
    hasPermission(item, type) {
      let level = localStorage.getItem("level");
      let merchant_id = localStorage.getItem("merchantId");
      if (item.id === merchant_id) {
        return false;
      } else {
        switch (type) {
          case 1:
            if (level === '1' || merchant_id === item.parentId) {
              return true;
            } else {
              return false;
            }
          case 2:
            if (merchant_id === item.parentId) {
              return true;
            } else {
              return false;
            }
        }
      }
    },
    formatStatus(row, column) {
      let status = row.status;
      if (status === "10") {
        return "营业中"
      } else if (status === "11") {
        return "暂停营业"
      }
    },
    operate(value, type) {
      let _this = this
      let params = {
        id: value
      }
      let url = '/backend/merchant/';
      if (type === 1) {
        url = url + 'restartMerchant';
      }
      if (type === 2) {
        url = url + 'pauseMerchant';
      }
      if (type === 3) {
        url = url + 'forbidMerchant';
      }
      _this.fullscreenLoading = true
      adminHttp.post(url, params).then(result => {
        if (result && result.code === 200) {
          _this.$message.success("操作成功");
          _this.getList();
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    getList() {
      let _this = this
      let params = {
        page: _this.currentPage - 1,
        page_size: _this.pageSize,
        merName: _this.merName,
        type: _this.type,
        status: _this.status,
      }
      _this.fullscreenLoading = true
      adminHttp.post('/backend/merchant/queryMerchantList', params).then(result => {
        if (result && result.code === 200) {
          if (result.data !== null) {
            _this.list = [];//result.data.content;
            // console.log(result.data.content)
            for (let item of result.data.content){
              if (item.autoReceipt == true){
                item.autoReceiptLabel = "是";
              }else{
                item.autoReceiptLabel = "否";
              }
              _this.list.push(item);
            }
            _this.total = result.data.totalElements;
          } else {
            _this.list = [];
            _this.total = 0;
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    handleCurrentChange(page) {
      this.currentPage = page - 1
      this.$nextTick(() => {
        this.getList()
      })
    },
    watchItem(id) {
      this.$router.push({path: `/admin/merchant_manage/watch/${id}`})
    },
    modifyRate(item) {
      this.rateParams.id = item.id;
      this.rateParams.parentRate = item.parentRate;
      this.rateParams.intoAccountDuration = item.intoAccountDuration;
      this.rateParams.autoReceipt = item.autoReceipt;
      this.rateParams.rate = item.rate;
      this.isVisible = true;
    },
    clearRateParams() {
      this.rateParams = {
        id: null,
        parentRate: null,
        autoReceipt:null,
        rate:null
      }
      this.isVisible = false;
      this.getList();
    }, modify() {
      let _this = this
      let params = {
        merchantId: this.rateParams.id,
        parentRate: this.rateParams.parentRate,
        intoAccountDuration: this.rateParams.intoAccountDuration,
        autoReceipt:this.rateParams.autoReceipt,
        rate: this.rateParams.rate
      }
      // console.log(params)
      this.$refs.rateForm.validate((valid) => {
        if (valid) {
          _this.fullscreenLoading = true
          adminHttp.post('/backend/merchant/modifyMerchantRate', params).then(result => {
            if (result && result.code === 200) {
              _this.$message.success("费率调整成功");
              _this.isVisible = false;
              _this.getList();
            } else {
              _this.$message.error("费率调整失败")
            }
          }).catch(error => {
            _this.$message.error('费率调整失败')
          }).finally(() => {
            _this.fullscreenLoading = false
          })
        }
      })
    },modifyInformation(id) {
      this.$router.push({path: `/admin/merchant_manage/edit/${id}`})
    }
  }
}
</script>

<style scoped>
.link {
  text-decoration: underline;
  color: blue;
}
</style>